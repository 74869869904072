<template>
  <div class="iss-main-grid">
    <!--     分页 -->
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap">
      <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1000, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record">
            <template #goDetail>
              <div class="primary-text cursor-p" @click="handleGotoView($event, record)">详情</div>
            </template>
          </operation>
        </template>

        <template #tradeType="{ record }">
          <!--        数量样式处理   -->
          <div v-if="record.tradeType === 'add'">
            收入
          </div>
          <div v-if="record.tradeType === 'decrease'">
            支出
          </div>
        </template>

        <template #goldNum="{ record }">
          <!--        数量样式处理   -->
          <div v-if="record.tradeType === 'add'" class="activeColor">
            +
            <span>{{record.goldNum}}</span>
          </div>
          <div v-if="record.tradeType === 'decrease'">
            -
            <span>{{record.goldNum}}</span>
          </div>
        </template>

        <!--      订单分类   -->
        <template #goldSource="{ record }">
          <!--        数量样式处理   -->
          <div v-if="record.goldSource === 1">注册</div>
          <div v-if="record.goldSource === 8">新手任务</div>
          <div v-if="record.goldSource === 5">新手指引</div>
          <div v-if="record.goldSource === 3">金币充值</div>
          <div v-if="record.goldSource === 2">邀请奖励</div>
          <div v-if="record.goldSource === 6">兑换使用时长</div>
          <div v-if="record.goldSource === 11">兑换云空间</div>
          <div v-if="record.goldSource === 9">短信发送消耗</div>
          <div v-if="record.goldSource === 10">短信退还</div>
          <div v-if="record.goldSource === 7">云空间结余</div>
        </template>



      </grid>
    </div>
  </div>
  <gold-view
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleView"
  />
</template>

<script>
import { reactive, ref, toRefs, } from 'vue';
// import { Row, Col, } from 'ant-design-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import ordersApi from "@/api/orders";
// import {useRouter} from "vue-router";
import goldView from "@/views/user/components/goldView";
import { message } from 'ant-design-vue';
import { downloadExcel } from '@/utils';
import SearchForm from '@/components/searchForm';
import {useRoute} from "vue-router";

export default {
  components: {
    // ARow: Row,
    // ACol: Col,
    Operation,
    Grid,
    SearchForm,
    goldView,

  },
  setup() {
    const gridRef = ref();
    const route = useRoute();
    const { id } = route.params
    // const router = useRouter();
    const state = reactive({
      search: {},
      TagList: [],
      activeKey: '1',
      visible: false,
      activeItem: {},

    });

    return {
      gridRef,
      ...toRefs(state),
      items: [
        //  需要补上日期时间筛选
        {
          key: 'date',
          timeKey: ['startCreateTime', 'endCreateTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        {
          key: 'goldSource',
          label: '订单类型',
          type: 'select',
          dataset: [
            { label: '注册', value: 1 },
            { label: '新手任务', value: 8 },
            { label: '新手指引', value: 5 },
            { label: '金币充值', value: 3 },
            { label: '邀请奖励', value: 2 },
            { label: '兑换使用时长', value: 6 },
            { label: '兑换云空间', value: 11 },
            { label: '短信发送消耗', value: 9 },

            { label: '短信退还', value: 10 },
            { label: '云空间结余', value: 7 },
          ],
        },
        {
          key: 'tradeType',
          label: '收支类型',
          type: 'select',
          dataset: [
            { label: '收入', value: 'add' },
            { label: '支出', value: 'decrease' },
          ],
        },

      ],
      columns: [
        { dataIndex: 'packageTitle', title: '订单名称', width: 150, ellipsis: true },
        { dataIndex: 'tradeType', title: '收支类型', width: 180, ellipsis: true,
          slots: { customRender: 'tradeType' },
        },
        { dataIndex: 'goldNum', title: '金币数量', width: 180, ellipsis: true,
          slots: { customRender: 'goldNum' },
        },
        { dataIndex: 'goldSource', title: '订单类型', width: 120, ellipsis: true,
          slots: { customRender: 'goldSource' },
        },
        { dataIndex: 'createTime', title: '创建时间', width: 180, ellipsis: true },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            ordersApi
                .excelExportData('contact:export', {userId: id})
                .then(({ name, data }) => downloadExcel(name, data));

          },
        },
        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: (ids) => {
            if (ids.length) {
              ordersApi
                  .excelExportData('contact:export', {userId: id, idList:[...ids]})
                  .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }

          },
        },
      ],
      options: [
        {
          type: 'goDetail',
          slotName: 'goDetail',
          title: '详情',
          permission: 'user:goDetail',
          fnClick: ({ id }) => {
            // router.push({
            //   name: '',
            //   query: {
            //     id,
            //   },
            // });
            console.log(id,);
          },
        },
      ],
      url: `${ordersApi.pFourUrl}`+ `?userId=${id}`,
      gridHeight: document.body.clientHeight - 548,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleView: (value) => {
        state.visible = value
      },
      handleGotoView: ($event, record)=> {
        // 查询金币流水
        ordersApi.goleDetail('', record.id).then(res => {
          if (res) {
            // console.log('res金币流水', res)
            state.activeItem = res
            // console.log('dddd', state.activeItem)
          }
        });
        state.visible = true

      },

    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    width: 85%;
    font-size: 16px;
    word-wrap: break-word;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    width: 15%;
    .icon {
      font-size: 28px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  //font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
// 个人信息
.userMes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .left {
    width: 36%;
    margin-right: 10px;
  }
  .right {
    width: 64%;
    text-align: left;
  }

}
.glod {
  text-align: center;
  background: #fff7ea;
  padding: 20px 0px;
  border-radius: 4px;
  .mun {
    color: #FF7B00;
  }
}


</style>