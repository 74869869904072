<template>
  <a-modal
      :visible="visible"
      title="金币流水详情"
      :mask-closable="false"
      :width="900"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
  >

    <div class="issTable  mt-20">
      <a-table :columns="columns" :data-source="data" :rowKey="(record) => record.id">
      </a-table>
    </div>

    <div class="issTable mt-10">
      <a-table :columns="columnsT" :data-source="dataT" :rowKey="(record) => record.id">
      </a-table>
    </div>

    <template v-slot:footer>
      <!--      <a-button @click="handleCancel">取消</a-button>-->
      <a-button type="primary" @click="handleOk" :loading="loading"
      >导出详情</a-button
      >
    </template>
  </a-modal>

</template>
<script>
import {reactive, toRefs, watch} from 'vue';
import { Table, } from 'ant-design-vue';
import ordersApi from "@/api/orders";
import {downloadExcel} from "@/utils";
// import { useStore } from 'vuex';


export default {
  components: {
    ATable: Table,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const store = useStore();
    const form = reactive({
      initVal: {},

    });

    const state = reactive({
      dataList: [],
      dataListT: [],
      loading: false,
      data:[
          {
            id: 1,
            packageName: '',
            userName: '',
            mobile: null,
            createTime: null,
          },
        ],
      dataT:[
          {
            id: 2,
            tradeType: '',
            packageName: '',
            goldNum: '',
            orderId: '',
          },
        ]


    });

    const columns = [
      {
        title: '订单名称',
        dataIndex: 'packageName',
        key: 'packageName',
      },
      {
        title: '用户昵称',
        dataIndex: 'userName',
        key: 'userName',
      },
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: '订单创建时间',
        key: 'createTime',
        dataIndex: 'createTime',
      },
    ];
    // const data = [
    //   {
    //     id: 1,
    //     packageName: '',
    //     userName: '',
    //     mobile: null,
    //     createTime: null,
    //   },
    // ];
    const columnsT = [
      {
        title: '收支类型',
        dataIndex: 'tradeType',
        key: 'tradeType',
      },
      {
        title: '分类',
        dataIndex: 'packageName',
        key: 'packageName',
      },
      {
        title: '金币数量',
        dataIndex: 'goldNum',
        key: 'goldNum',
      },
      // {
      //   title: '优惠',
      //   key: 'creaTime',
      //   dataIndex: 'creaTime',
      // },
      {
        title: '订单号',
        dataIndex: 'orderId',
        key: 'orderId',
      },
      // {
      //   title: '交易号',
      //   dataIndex: 'playType',
      //   key: 'playType',
      // },

    ];
    // const dataT = [
    //   {
    //     id: 2,
    //     tradeType: '',
    //     packageName: '',
    //     goldSource: '',
    //     orderId: '',
    //   },
    // ];

    const handleCancel = () => {
      context.emit('update:visible', false);
      // dataT[0] = {
      //     id: null,
      //     tradeType: '',
      //     packageName: '',
      //     goldSource: '',
      //     orderId: '',
      //   }
      // data[0] = {
      //     id: null,
      //     packageName: '',
      //     userName: '',
      //     mobile: null,
      //     createTime: null,
      // }

      // 表单重置
    };
    watch(
        () => props.init,
        value => {
          // console.log('value56', value);
          // 列表一
          form.initVal = value
          state.data[0].id = value.id
          state.data[0].mobile = value.mobile
          state.data[0].packageName = value.packageTitle
          state.data[0].userName = value.userName
          state.data[0].createTime = value.createTime


          // 列表二
          let type = ''
          if (value.tradeType === 'add') {
            type = '收入'
          } else if (value.tradeType === 'decrease') {
            type = '支出'
          } else {
            type = ''
          }

          let packageName = ''
          if (value.goldSource === 1) {
            packageName = '注册'
          } else if (value.goldSource === 8) {
            packageName = '新手任务'
          }else if (value.goldSource === 5) {
            packageName = '新手指引'
          }else if (value.goldSource === 3) {
            packageName = '金币充值'
          }else if (value.goldSource === 2) {
            packageName = '邀请奖励'
          }else if (value.goldSource === 6) {
            packageName = '兑换使用时长'
          }else if (value.goldSource === 11) {
            packageName = '兑换云空间'
          }else if (value.goldSource === 9) {
            packageName = '短信发送消耗'
          }else if (value.goldSource === 10) {
            packageName = '短信退还'
          }else if (value.goldSource === 7) {
            packageName = '云空间结余'
          }

          state.dataT[0].id = value.id
          state.dataT[0].tradeType = type
          state.dataT[0].packageName = packageName
          state.dataT[0].goldNum = value.goldNum
          state.dataT[0].orderId = value.id
        }
    );

    return {
      ...toRefs(state),
      form,
      handleCancel,
      columns,
      columnsT,
      handleOpen() {
        state.openTiesGroup = true;
        // context.emit('fnOk', false);
      },
      handleOk: () => {
        //
        ordersApi
            .excelExportData('contact:export', {})
            .then(({ name, data }) => downloadExcel(name, data));
        // .then((data) => downloadExcel('用户金币详情数据.xlsx', data));

        context.emit('fnOk', false);

      },

    };
  },
};
</script>
<style lang="less" scoped>
.panel {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #eeeeee;
}

.objStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  margin-bottom: 20px;
  margin: 20px 0px;
  padding: 10px 5px;

  .objDataStyle {

    .objKey {
      //background: #eeeeee;
    }
    .objObj {
      margin-top: 20px;
    }
  }
}

.issTable /deep/ .ant-table-pagination.ant-pagination {
  display: none;
}
.issTable /deep/ .ant-table-tbody > tr > td {
  border-bottom: none;
}

</style>
